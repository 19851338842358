<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <ecommerce-medal :data="vendedorDelMes" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <ecommerce-statistics
          :data="dataEstadisticas"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col>
          <!--/ Bar Chart - Orders -->
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col>
          <b-col
            lg="12"
            md="6"
          >
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col>
        </b-row>
      </b-col>

      <!-- Revenue Report Card -->
      <b-col lg="8">
        <ecommerce-revenue-report :data="data.revenue" />
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="8">
        <ecommerce-company-table :data-list="dataProductos" />
      </b-col>
      <!--/ Company Table Card -->

      <!-- Developer Meetup Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-meetup :data="data.meetup" />
      </b-col>
      <!--/ Developer Meetup Card -->

      <b-col cols="12">
        <invoice-list :data-list="dataReporteVentasVendedor" />
      </b-col>

      <!-- Browser States Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-browser-states
          :data="dataProduccion"
        />
      </b-col>
      <!--/ Browser States Card -->

      <!-- Goal Overview Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-goal-overview :data="data.goalOverview" />
      </b-col>
      <!--/ Goal Overview Card -->

      <!-- Transaction Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-transactions :data="data.transactionData" />
      </b-col>
      <!--/ Transaction Card -->
    </b-row>
  </section>
</template>

<script>
import store from '@/store'
import { BRow, BCol } from 'bootstrap-vue'

// import { getUserData } from '@/auth/utils'
import moment from 'moment'
import InvoiceList from '@/views/erp/comercial/dashboard/gerencia/InvoiceList.vue'
import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import EcommerceMeetup from './EcommerceMeetup.vue'
import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import EcommerceTransactions from './EcommerceTransactions.vue'

export default {
  components: {
    BRow,
    BCol,

    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
    InvoiceList,
  },
  data() {
    return {
      data: {},
      isShow: false,
      totalClientes: 0,
      dataEstadisticas: [],
      dataProduccion: [],
      dataProductos: [],
      currentDate: moment().format('YYYY-MM-DD'),
      startDateCurrentMonth: moment().startOf('month').format('YYYY-MM-DD'),
      filters: {
        idVendedor: -1,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        isSend: true,
      },
      dataReporteTopVendedores: [],
      vendedorDelMes: {},
      dataReporteVentasVendedor: [],
    }
  },
  created() {
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data
        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        // const userData = getUserData()
        // this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
  },
  mounted() {
    this.getDashboard()
    this.getReporteTopVendedores()
    this.getReporteVentasProductos()
    this.getReporteVentasVendedor()
  },
  methods: {
    async getDashboard() {
      this.isBusy = true
      this.isShow = true
      await store
        .dispatch('comercial/DASHBOARD_GET_DASHBOARD', {
          from: 'gerencia',
        })
        .then(response => {
          this.totalClientes = response.total_clientes
          this.dataEstadisticas.push({
            color: 'light-primary',
            customClass: 'mb-2 mb-xl-0',
            icon: 'TrendingUpIcon',
            subtitle: 'Preventa',
            title: '230k',
          })
          this.dataEstadisticas.push({
            color: 'light-info',
            customClass: 'mb-2 mb-xl-0',
            icon: 'UserIcon',
            subtitle: 'Clientes',
            title: this.totalClientes,
          })
          this.dataEstadisticas.push({
            color: 'light-danger',
            customClass: 'mb-2 mb-sm-0',
            icon: 'BoxIcon',
            subtitle: 'Products',
            title: '1.423k',
          })
          this.dataEstadisticas.push({
            color: 'light-success',
            customClass: '',
            icon: 'DollarSignIcon',
            subtitle: 'Ventas',
            title: '1.423k',
          })

          this.dataProduccion.push({
            browserImg: require('@/assets/images/icons/tanque_estacionario.png'),
            name: 'Tanque Estacionario',
            usage: `${response.tanque_estacionario.porcCapacidadActual}%`,
          })

          this.dataProduccion.push({
            browserImg: require('@/assets/images/icons/tanque_pulmon.png'),
            name: 'Tanque Pulmón',
            usage: `${response.tanque_pulmon.porcCapacidadActual}%`,
          })

          this.dataProduccion.push({
            browserImg: require('@/assets/images/icons/gasocentro.png'),
            name: 'Gasocentro',
            usage: `${response.gasocentro.porcCapacidadActual}%`,
          })

          // this.dataProductos = response.productos

          this.isBusy = false
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
      this.isShow = true
    },
    async getReporteTopVendedores() {
      await store
        .dispatch('facturacion/GET_REPORTE_TOP_VENDEDORES', {
          startDate: this.startDateCurrentMonth,
          endDate: this.filters.endDate,
          isSend: this.filters.isSend,
        })
        .then(response => {
          this.dataReporteTopVendedores = response
          this.vendedorDelMes = {
            nombreVendedor: this.dataReporteTopVendedores[0].nombreVendedor,
            total: this.dataReporteTopVendedores[0].total,
          }
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
      this.isShow = true
    },
    async getReporteVentasProductos() {
      await store
        .dispatch('facturacion/GET_REPORTE_VENTAS_PRODUCTOS', {
          startDate: this.startDateCurrentMonth,
          endDate: this.filters.endDate,
          isSend: this.filters.isSend,
        })
        .then(response => {
          this.dataProductos = response
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
      this.isShow = true
    },
    async getReporteVentasVendedor() {
      await store
        .dispatch('facturacion/GET_REPORTE_VENTAS_VENDEDOR', {
          idVendedor: this.filters.idVendedor,
          startDate: '2022-01-01', // this.startDateCurrentMonth,
          endDate: this.filters.endDate,
          isSend: this.filters.isSend,
        })
        .then(response => {
          this.dataReporteVentasVendedor = response
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
      this.isShow = true
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
