<template>
  <b-card
    v-if="dataList"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="dataList"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- company -->
      <template #cell(descPresentacion)="value">
        <div class="d-flex align-items-center">
          <b-avatar
            rounded
            size="32"
            variant="light-company"
          >
            <b-img
              :src="require(`@/assets/images/icons/${getImgUrl(value.item.capacidadPresentacion)}`)"
              alt="avatar img"
            /></b-avatar>
          <div>
            <div class="font-weight-bolder">
              {{ value.item.descPresentacion }}
            </div>
            <div class="font-small-2 text-muted">
              {{ value.item.marcaPresentacion }}
            </div>
          </div>
        </div>
      </template>

      <!-- category -->
      <template #cell(category)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            class="mr-1"
            :variant="data.item.avatarColor"
          >
            <feather-icon
              size="18"
              :icon="data.item.avatarIcon"
            />
          </b-avatar>
          <span>{{ data.item.avatarTitle }}</span>
        </div>
      </template>

      <!-- views -->
      <template #cell(stockActual)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder mb-25">{{ data.item.stockActual }}</span>
          <span class="font-small-2 text-muted text-nowrap">UND</span>
        </div>
      </template>

      <!-- revenue -->
      <template #cell(precioDetalleVenta)="data">
        {{ 'S/.'+data.item.precioDetalleVenta }}
      </template>

      <!-- sales -->
      <template #cell(subTotal)="data">
        <span class="font-weight-bolder">{{ 'S/.' + data.item.subTotal }}</span>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        { key: 'descPresentacion', label: 'PRODUCTO' },
        { key: 'cantidad', label: 'CANTIDAD', tdClass: 'text-center', thClass: 'text-center' },
        { key: 'precioDetalleVenta', label: 'PRECIO VENTA', tdClass: 'text-center', thClass: 'text-center' },
        { key: 'subTotal', label: 'VENTAS', tdClass: 'text-center', thClass: 'text-center' },
      ],
    }
  },
  methods: {
    getImgUrl(val) {
      if (val === 5 || val === 10 || val === 15 || val === 45) {
        return `cilindro-de-gas-${val}.png`
      }
      return 'cilindro-de-gas.png'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
